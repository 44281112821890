/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
@media (max-width: 768.98px) {
  .experience-commerce_layouts-navRow .nav-row {
    padding: 0; } }

.experience-commerce_layouts-navRow .anchor-link,
.experience-commerce_layouts-navRow .nav-link {
  display: block;
  width: auto;
  text-align: center;
  padding: 0;
  margin: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  color: var(--skin-main-text-color); }
  @media (min-width: 769px) {
    .experience-commerce_layouts-navRow .anchor-link,
    .experience-commerce_layouts-navRow .nav-link {
      width: 8rem; } }
  .experience-commerce_layouts-navRow .anchor-link picture,
  .experience-commerce_layouts-navRow .nav-link picture {
    width: 100%; }
  .experience-commerce_layouts-navRow .anchor-link .nav-link-image,
  .experience-commerce_layouts-navRow .nav-link .nav-link-image {
    border-radius: 0;
    border-color: var(--color-white);
    border-style: none;
    border-width: 0;
    background-color: transparent; }
    .experience-commerce_layouts-navRow .anchor-link .nav-link-image.add-border,
    .experience-commerce_layouts-navRow .nav-link .nav-link-image.add-border {
      border-color: var(--color-grey2);
      border-width: 0.125rem 0.375rem 0.375rem 0.125rem; }
  .experience-commerce_layouts-navRow .anchor-link .anchor-title,
  .experience-commerce_layouts-navRow .anchor-link .nav-title,
  .experience-commerce_layouts-navRow .nav-link .anchor-title,
  .experience-commerce_layouts-navRow .nav-link .nav-title {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 1rem 0 0; }
  .experience-commerce_layouts-navRow .anchor-link:hover,
  .experience-commerce_layouts-navRow .nav-link:hover {
    color: var(--color-blue); }
  .experience-commerce_layouts-navRow .anchor-link.active,
  .experience-commerce_layouts-navRow .nav-link.active {
    pointer-events: none; }
  .experience-commerce_layouts-navRow .anchor-link.no-image,
  .experience-commerce_layouts-navRow .nav-link.no-image {
    text-transform: none;
    padding: 0.5rem; }
    @media (min-width: 769px) {
      .experience-commerce_layouts-navRow .anchor-link.no-image,
      .experience-commerce_layouts-navRow .nav-link.no-image {
        padding: 1.5rem 1rem; } }
    .experience-commerce_layouts-navRow .anchor-link.no-image .anchor-title,
    .experience-commerce_layouts-navRow .anchor-link.no-image .nav-title,
    .experience-commerce_layouts-navRow .nav-link.no-image .anchor-title,
    .experience-commerce_layouts-navRow .nav-link.no-image .nav-title {
      margin: 0; }

.experience-commerce_layouts-navRow .swiper-slide {
  max-width: 8rem;
  margin: 0.5rem 0.3125rem 0; }
  @media (min-width: 769px) {
    .experience-commerce_layouts-navRow .swiper-slide {
      margin: 0.5rem 1rem 0; } }
  .experience-commerce_layouts-navRow .swiper-slide > .row {
    margin: 0; }
    .experience-commerce_layouts-navRow .swiper-slide > .row > .col-12 {
      padding: 0; }

.experience-commerce_layouts-navRow .swiper-scrollbar {
  width: 94%;
  left: 3%; }

body.bearcave.dark-theme .experience-component .anchor-link,
body.bearcave.dark-theme .experience-component .nav-link,
body.bearcave .dark-theme .experience-component .anchor-link,
body.bearcave .dark-theme .experience-component .nav-link,
body.bearcave .dark-bg .experience-component .anchor-link,
body.bearcave .dark-bg .experience-component .nav-link {
  color: var(--color-white); }
  body.bearcave.dark-theme .experience-component .anchor-link .nav-link-image,
  body.bearcave.dark-theme .experience-component .nav-link .nav-link-image,
  body.bearcave .dark-theme .experience-component .anchor-link .nav-link-image,
  body.bearcave .dark-theme .experience-component .nav-link .nav-link-image,
  body.bearcave .dark-bg .experience-component .anchor-link .nav-link-image,
  body.bearcave .dark-bg .experience-component .nav-link .nav-link-image {
    border-color: var(--color-white);
    background-color: transparent; }
